import "./Contacts.css";
import { SlPhone } from "react-icons/sl";
import Helmet from "react-helmet";


function Contacts () {
    const maps = <div className="mapouter"><div class="gmap_canvas"><iframe width="100%" height="600" id="gmap_canvas" src="https://maps.google.com/maps?q=Stryi%20Dentex&t=&z=13&ie=UTF8&iwloc=&output=embed&v=3.53" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://putlocker-is.org"></a><a href="https://www.embedgooglemap.net"></a><style></style></div></div>

    return <div className="Contacts">
        <Helmet>
          <title>Контакти Dentex</title>
          <meta
              name="description"
              content="Контакти сторінка сайту Dentex, стоматологія в Стрию."
          />
        </Helmet>

        <div className="headerLabel">
          <h1>Контакти</h1>
        </div>

      {/* <div></div> */}

      {["+380 671 526 192", "+380 979 077 366"].map(phone => {
        return <a href={"tel:" + phone.split(' ').join('-')}><SlPhone size={20} className="PhoneIcon"/>{phone}</a>
      })}

      {maps}
    </div>
}

export default Contacts;
