import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as firebase from "firebase/app";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { hydrate, render } from "react-dom"
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBgMNJa78BViA8ABTYW84YD5ugT8t-GwOg",
  authDomain: "dentex-f72ff.firebaseapp.com",
  projectId: "dentex-f72ff",
  storageBucket: "dentex-f72ff.appspot.com",
  messagingSenderId: "975731839155",
  appId: "1:975731839155:web:c8620cd9cbc6c995a6a0c6",
  measurementId: "G-YES1J03MGD",
  databaseURL: "https://dentex-f72ff-default-rtdb.europe-west1.firebasedatabase.app"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

 
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
