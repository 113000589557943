import React, { useState } from "react";
import { Fade as Hamburger } from 'hamburger-react'
import { NavLink } from "react-router-dom";
import "./DrawerMenu.css";
import MenuOpenIcon from "../../images/menuopen.png";
import { SlMap, SlHome, SlNotebook, SlFolderAlt } from "react-icons/sl";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

function DrawerMenu () {
    let [show, setShow] = useState(false);
    const { t } = useTranslation();

    function showDrawer () {
        window.scroll(0, 0);
        setShow(!show);
    };

    const urls = [
        ["/", t("homeNavbar"), <SlHome/>],
        ["/price", t("priceNavbar"), <SlNotebook/>],
        ["/ourworks", t("ourworksNavbar"), <SlFolderAlt/>],
        ["/contacts", t("contactsNavbar"), <SlMap/>]
    ];

    return (
        <div className="DrawerMenu">
          <div className={show ? "Cover-hide Cover" : "Cover-hide"} onClick={() => showDrawer()}></div>
            <div className="ShowIcon" >
                <Hamburger toggled={show} toggle={setShow} size={40} />
            </div>

            <div className={show ? 'show DrawerItems' : 'DrawerItems'}>
                <ul>
                    {urls.map((url) => {
                        if (!url)
                            return null
                        return <NavLink active={true} onClick={() => showDrawer()} to={url[0]} key={url[0]} exact="true">
                            <div className="DrawerItem">
                                <li>{url[2]} {url[1]?url[1]:undefined}</li>
                            </div>
                        </NavLink>
                    })}

                    <ToggleButtonGroup type="radio" name="options" defaultValue={1} onChange={(tg) => {
                        i18next.changeLanguage(tg === 1 ? "ua": "en")
                    }}>
                        <ToggleButton id="tbg-radio-1" value={1}>
                        UA
                        </ToggleButton>
                        <ToggleButton id="tbg-radio-2" value={2}>
                        EN
                        </ToggleButton>
                    </ToggleButtonGroup>
                </ul>
            </div>

            
        </div>
    )
}

export default DrawerMenu;
