import React, { useEffect, useState } from "react";
import "./Price.css";
import { getDatabase, onValue, ref, goOffline, goOnline } from "firebase/database";
import * as firebase from "firebase/app";
import Helmet from "react-helmet";

function Price () {
    const defaultPrices = [
        [ "Консультація лікаря", "350", "₴" ],
        [ "Анестезія аплікаційна", "250", "₴" ],
        [ "Анестезія інфільтраційна", "180", "₴" ],
        [ "Кофердам", "250", "₴" ],
        [ "Пломба фотополімерна", "1400-1800", "₴" ],
        [ "Реставрація", "1800-3000", "₴" ],
        [ "Лікування пульпіту молочного зуба", "1200", "₴" ],
        [ "Герметизація фісур (один зуб)", "700", "₴" ],
        [ "Тимчасова пломба", "100", "₴" ],
        [ "Фіксація скловолоконного штифта", "1900", "₴" ],
        [ "Розпломбування одного каналу", "100-500", "₴" ],
        [ "Постійна обтурація", [
            [ "Один канал", "1100", "₴"],
            [ "Два канали", "2200", "₴" ],
            [ "Три канали", "3300", "₴" ]
        ]],
        [ "Професійна гігієна", "1500-2000", "₴"],
        [ "Накладання пародонтальної пов'язки(одна щелепа)", "500", "₴" ],
        [ "Закриття перфораці(мта)", "500", "₴" ],
        [ "Видалення молочного зуба", "600", "₴" ],
        [ "Видалення постійного зуба", "1500-3000", "₴" ],
        [ "Куксова вкладка", "700", "₴" ],
        [ "Коронка металокерамічна VITA", "1800", "₴" ],
        [ "Коронка суцільнолита", "1000", "₴" ],
        [ "Коронка церконія", "150-180", "€" ],
        [ "Коронка пластм. тимчас.", "700", "₴" ],
        [ "Вініри", "180", "€" ],
        [ "Протез знімний пластинчастий", "5500-7000", "₴" ],
        [ "Коронка безметалева (CERCON)", "140-160", "€" ],
        [ "Вініри", "180", "€" ],
        [ "Цементування коронки", "450", "₴" ],
        [ "Цементування вкладки", "350", "₴" ],
        [ "Ренген", "250", "₴" ],
        [ "Відбитки (А силікон)", "1000", "₴" ],
    ]

    const [prices, setPrices] = useState([]);

    useEffect( () => {
        // goOnline();
        // const db = getDatabase();
        // goOnline(db);
        // const dbref = ref(db, 'Pricelist');
        // onValue(dbref, (snapshot => {
        //     const data = snapshot.val();

        //     goOffline(db);

        //     const trueData = [];

        //     for (const name in data) {
        //         const pushData = [name];

        //         let isArray = false;
        //         for (const price in data[name]) {
        //             if (!isNaN(parseFloat(price.split(" ").join("").split("-").join("")))) {
        //                 pushData.push(price);
        //                 pushData.push(data[name][price]);
        //             } else {
        //                 if (isArray == false)
        //                     pushData.push([]);
        //                 isArray = true;
        //                 for (const undername in data[name][price]) {
        //                     const underPushdata = [price, undername, data[name][price][undername]];


        //                     pushData[1].push(underPushdata);
        //                     const sortPushData = pushData[1];

        //                     sortPushData.sort((a, b) => {
        //                         return a[1] - b[1]  
        //                     })

        //                     pushData[1] = sortPushData;
        //                 }
        //             }
        //         }
        //         trueData.push(pushData)
        //     }

        //     // console.log(trueData)
        //     setPrices(trueData)
        // }))

        // // 
    }, [])

    return (
        <div className="Price">
            <Helmet>
                <title>Прайслист Dentex</title>
                <meta
                    name="description"
                    content="Сторінка з цінами сайту Dentex, стоматологія в Стрию."
                />
            </Helmet>
            <div className="headerLabel"><h1>Прайслист</h1></div>

            <div>
                {defaultPrices.map((item) => {
                    return <div className="PriceItem">
                        {
                            item[0].length < 30
                            ?
                            (
                                <span className="PriceItemName">{item[0]}</span>
                            ):
                            (
                                <span className="PriceItemName LargePriceItem">{item[0]}</span>   
                            )
                        }
                        {typeof item[1] == "string"? <span className="PriceItemCount">{item[1] + " " + item[2]}</span>:
                            item[1].map( underItem => {
                                return <div className="PriceItem">
                                    (
                                        <span className="PriceItemName">{underItem[0]}</span>
                                    )
                                    <span className="PriceItemCount">{underItem[1]+ " " + underItem[2]}</span>
                                </div>
                            })
                            
                        }
                    </div>
                })}
            </div>
        </div>
    )
}

export default Price;
