import React from "react";
import Background from "../Background/Background";
import "./Home.css";
import { useTranslation } from "react-i18next";
import Helmet from "react-helmet";

function Home () {
    const { t } = useTranslation();

    return (
        <div className="Home">
            <Helmet>
                <title>Home Dentex</title>
                <meta
                    name="description"
                    content="Головна сторінка сайту Dentex, стоматологія в Стрию."
                />
            </Helmet>

            <Background/>

            <div className="labels">
                <div className="familyToothLabel"><h1>{t("familyLabel")}</h1></div>
                <div className="dentexLabel">DENTEX</div>
            </div>
        </div>
    )
}

export default Home;