import "./OurWorks.css";
import case11 from "./../../images/case1.1.jpg";
import case12 from "./../../images/case1.2.jpg";
import case13 from "./../../images/case1.3.jpg";

import case21 from "./../../images/case2.1.jpg";
import case22 from "./../../images/case2.2.jpg";

import case31 from "./../../images/case3.1.jpg";
import case32 from "./../../images/case3.2.jpg";

import case41 from "./../../images/case4.1.jpg";
import case42 from "./../../images/case4.2.jpg";

import case51 from "./../../images/case5.1.jpg";
import case52 from "./../../images/case5.2.jpg";
import case53 from "./../../images/case5.3.jpg";


//import case51 from "./../../images/case"
import { Accordion } from "react-bootstrap";
import Helmet from "react-helmet";


function OurWorks () {
    return <div className="OurWorks">
        <Helmet>
          <title>Наші роботи</title>
          <meta
              name="description"
              content="Сторінка з роботами сайту Dentex, стоматологія в Стрию."
          />
        </Helmet>

        <div className="headerLabel"><h1>Наші роботи</h1></div>
        
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0" active>
                <Accordion.Header>Case 1</Accordion.Header>
                <Accordion.Body>
                <div><b>До</b></div>
                <img className="Works-img" src={case11} alt="" width={240}/>
                <img src={case12} alt="" width={240}/>
                <br/>
                
                <img className="Works-img" src={case13} alt="" width={240}/>
                <div><b>Після</b></div>
                <br/>
                для правильного прикусу та покращення естетики,пацієнту було проведено ортодонтичне та ортопедичне лікування!для отримання грамотного результату важливо пройти всі етапи лікування!
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
                <Accordion.Header>Case 2</Accordion.Header>
              <Accordion.Body>
                <div><b>До</b></div>
                <img className="Works-img" width={240} src={case21} alt=""/>

                <img className="Works-img" width={240} src={case22} alt=""/>

                <div><b>Після</b></div>
                <br/>

                тотальна реконструкція та відновлення естетичної посмішки за допомогою коронок,виготовлених з діоксид-цирконію
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>Case 3</Accordion.Header>

              <Accordion.Body>
                <div><b>До</b></div>

                <img width={240} src={case31} alt="" className="Works-img" />

                <img width={240} src={case32} alt="" className="Works-img" />
                <div><b>Після</b></div>
                <br/>
                відновлення естетичної посмішки за допомогою двох коронок ,виготовлених з діоксид-цирконію
                </Accordion.Body>
            </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>Case 4</Accordion.Header>
            <Accordion.Body>
              <div><b>До</b></div>
              <img src={case41} width={240} className="Works-img"/>
                <img src={case42} width={240} className="Works-img"/>
              <div><b>Після</b></div>
              <br/>
              тотальна реабілітація з підняттям прикусу пацієнтки та створенням естетичної усмішки за допомогою коронок виготовлених з діоксид цирконію
            
            </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
            <Accordion.Header>Case 5</Accordion.Header>
            <Accordion.Body>
            <div><b>До</b></div>
                <img className="Works-img" src={case51} alt="" width={240}/>
                <img src={case52} alt="" width={240}/>
                <br/>
                
                <img className="Works-img" src={case53} alt="" width={240}/>
                <div><b>Після</b></div>
              <br/>
              відновлення повноцінної жувальної функції та естетичного вигляду за допомогою дентальних імплантів
            
            </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </div>
}

export default OurWorks;
