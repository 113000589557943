import React from "react"
import logo from "./../../images/logo.svg";
import "./Background.css";

function Background () {
    return (
        <div className="Background">
            <img className="image" src={logo} alt=""/>
        </div>
    )
}

export default Background;