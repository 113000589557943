import "./Footer.css";
import React from "react";
import DentexLogo from "../../images/Dentex_logo.svg";
import { SlLocationPin } from "react-icons/sl";
import { SlPhone } from "react-icons/sl";
import { Link } from "react-router-dom";

function Footer () {
    return <div className="Footer">
        <div className="Footer_IconsBar">
            <Link onClick={() => {
                window.location.href = 'https://www.google.com/maps/place/Dentex,+%D0%B2%D1%83%D0%BB%D0%B8%D1%86%D1%8F+%D0%90%D0%BD%D0%B4%D1%80%D1%96%D1%8F+%D0%9A%D0%BE%D1%80%D1%87%D0%B0%D0%BA%D0%B0,+10,+%D0%A1%D1%82%D1%80%D0%B8%D0%B9,+%D0%9B%D1%8C%D0%B2%D1%96%D0%B2%D1%81%D1%8C%D0%BA%D0%B0+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+%D0%A3%D0%BA%D1%80%D0%B0%D0%B8%D0%BD%D0%B0,+82400/@49.2589305,23.8556177,13z/data=!4m6!3m5!1s0x473a69f58496ceef:0x70a0d76e51d4620d!8m2!3d49.2589305!4d23.8556177!16s%2Fg%2F11hdy2ps3y?hl=ru-RU&gl=US';
            }}><div className="Footer_icon"><SlLocationPin size={30} color="white"/></div></Link>
            <Link to="/contacts"><div className="Footer_icon"><SlPhone size={30} color="white" /></div></Link>
        </div>
        <div className="Footer_dentex-logo">
            <img width="250" src={DentexLogo} alt="" />
        </div>
        <div className="privacy">© 2023 All Rights Reserved Terms of Use and Privacy Policy</div>
    </div>
}

export default Footer;