import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Home from './Components/Home/Home';
import About from './Components/About/About';
import DrawerMenu from './Components/DrawerMenu/DrawerMenu';
import Price from './Components/Price/Price';
import OurWorks from './Components/OurWorks/OurWorks';
import Contacts from './Contacts/Contacts';
import Footer from './Components/Footer/Footer';
import React, { Suspense} from 'react';
import i18next from "i18next";
import { withTranslation } from 'react-i18next';
import { initReactI18next } from "react-i18next";

const translationEn = {
    homeNavbar: "Home",
    priceNavbar: "Pricelist",
    ourworksNavbar: "Our works",
    contactsNavbar: "Contact",
    familyLabel: "FAMILY DENTISTRY"
};

const translationUa = {
    homeNavbar: "Головна",
    priceNavbar: "Прайслист",
    ourworksNavbar: "Наші роботи",
    contactsNavbar: "Контакти",
    familyLabel: "СІМЕЙНА СТОМАТОЛОГІЯ"
};

i18next.use(initReactI18next).init({
    resources: {
        en: { translation: translationEn },
        ua: { translation: translationUa }
    },
    lang: "ua",
    fallbackLng: "ua",
    interpolation: { escapeValue: false },
});


class App extends React.Component {
  t;

  constructor (props) {
    super(props);
  }

  componentDidMount () {

    // dbref.on('value', (snapshot) => {
    //     console.log(snapshot.val());
    //   }, (errorObject) => {
    //     console.log('The read failed: ' + errorObject.name);
    // }); 
  }

  render () {
    return (
      <div className="App">
        <Suspense fallback="Loading...">
          <BrowserRouter>
            <DrawerMenu/>
            <div className="PageContent">
              <Routes>
                <Route path="/" element={<Home/>}></Route>

                <Route path="/about" element={<About/>}></Route>
                
                <Route path="/price" element={<Price/>}></Route>

                <Route path="/ourworks" element={<OurWorks/>}></Route>

                <Route path="/contacts" element={<Contacts/>}></Route>
              </Routes>
            </div>
            <Footer/>
          </BrowserRouter>
        </Suspense>
      </div>
    );
  }
}

export default withTranslation()(App);
